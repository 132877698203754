import React, { Fragment, useContext, useState } from 'react';
import { useGoogleLogin } from '@react-oauth/google';
import axios from 'axios';
import GoogleImg from './../../images/googleImg3.png';
import { AuthContext } from './../../context/AuthContext';
import Cookies from 'js-cookie';
import AppLoader from './../../views/dashboard/appLoader/AppLoader';
import '../.././views/styles.css'
import { ssoVisitorsLogs } from '../../helper/visitorsApi';

function base64URLEncode(str) {
    return str.toString('base64').replace(/\+/g, '-').replace(/\//g, '_').replace(/=/g, '');
}

const GoogleSSO = (props) => {
    const { requestToken, ssoLogin, verifier } = useContext(AuthContext);
    const { setSsoError } = props;
    const [isLoader, setLoader] = useState(false);

    const login = useGoogleLogin({
        onSuccess: tokenResponse => {
            const code_verifier = base64URLEncode(base64URLEncode(verifier));
            const requestData = {
                access_token: tokenResponse.access_token,
                expires_in: 3599,
                request_token: requestToken,
                code_verifier: code_verifier,
            }
            const infoToken = tokenResponse.access_token;
            setSsoError('');
            setLoader(true);
            axios.post(`${window.env.REACT_APP_API_TARGET_URL}/api/v1/oauth/sso/google`, requestData, {
                withCredentials: true,
            })
                .then(function (response) {
                    if (response && response.data) {
                        if (response.data.data !== null) {
                            ssoLogin(response.data.data.access_token);
                            setLoader(false);
                            Cookies.set('token', response.data.data.access_token);
                            ssoVisitorsLogs('success', infoToken);
                        } else {
                            setLoader(false);
                            setSsoError(response.data.message);
                            setTimeout(() => {
                                setSsoError('');
                            }, 20000);
                            ssoVisitorsLogs('failure', JSON.parse(response.config.data).access_token);
                        }
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });
        }
    });

    return (
        <Fragment>
            {isLoader && <AppLoader isAllow={false} />}
            <div className='spacer'>
                <span className='textbng'>OR</span>
            </div>
            <span onClick={() => login()}  className='gbwrap'>
                <img src={GoogleImg} alt="Login with google" className='gimg' />
                <span className='gtext'>Continue With Google</span>
            </span>
        </Fragment>
    )
}

export default GoogleSSO;