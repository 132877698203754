import React, { useEffect, useState } from 'react'
import OpenSeadragon from 'openseadragon'
import {
  fabric,
  initFabricJSOverlay
} from '@adamjarling/openseadragon-fabricjs-overlay'
import { makeStyles } from '@mui/styles'
import Button from '@mui/material/Button'
import LinearScaleIcon from '@mui/icons-material/LinearScale'
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked'
import ChangeHistoryIcon from '@mui/icons-material/ChangeHistory'
import CreateIcon from '@mui/icons-material/Create'
import AbcIcon from '@mui/icons-material/Abc'
import RectangleOutlinedIcon from '@mui/icons-material/RectangleOutlined'
import SelectionIcon from '@mui/icons-material/AdsClick'
import DeleteIcon from '@mui/icons-material/Delete'
import RemoveCircleOutlineOutlinedIcon from '@mui/icons-material/RemoveCircleOutlineOutlined'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogTitle from '@mui/material/DialogTitle'
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import ZoomOutOutlinedIcon from '@mui/icons-material/ZoomOutOutlined';
import ZoomInOutlinedIcon from '@mui/icons-material/ZoomInOutlined';
import StraightenIcon from '@mui/icons-material/Straighten';
import ShapeLineOutlinedIcon from '@mui/icons-material/ShapeLineOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Tooltip from '@mui/material/Tooltip';
import './styles.css';
import Popper from '@mui/material/Popper';
import { useDispatch, useSelector } from 'react-redux';
import Cookies from 'js-cookie'
import { saveAnnotationData } from 'action';


/** CSS for tool bar */

const slr = "slectRight"
const rcur = " leftcurve";
const rgcur = " rightcurve";

const useStyles = makeStyles(theme => ({
  tooltip: {
    fontSize: "2em !important",
  },
  main_Box: {
    display: 'flex',
    background: '-webkit-linear-gradient(top,#ccc,#eee)',
    width: '100%',
    height: '100%',
    padding: '10px 13%',
    borderBottom: '#cbd8e05c 1px solid'

  },
  shape_Box: {
    display: 'grid !important',
    textTransform: 'none !important'
  },
  shape_bgColor_Box: {
    display: 'grid !important',
    textTransform: 'none !important',
    backgroundColor: '#ccc !important',
    borderRadius: 'none !important'
  },
  rect_Icon: {
    margin: '0px',
  },
  circle_Icon: {
    marginLeft: '',
  },
  triangle_Icon: {
    marginLeft: '0px',
  },
  line_Icon: {
    marginLeft: '0px',
  },
  pencil_Icon: {
    marginLeft: '0px',
  },
  text_Icon: {
    marginLeft: '0px',
  },
  allStroke_Box: {
    display: 'flex',
    color: '#bbb',
  },
  stroke_clr_Box: {
    display: 'grid !important',
    textTransform: 'none !important',
    width: '40px !important',
  },
  stroke_clr_Field: {
    marginLeft: '0px',
    width: '35px !important',
  },
  fill_clr_Field: {
    marginLeft: '0px',
    width: '35px',
  },
  fill_clr_Box: {
    display: 'grid !important',
    textTransform: 'none !important',
    width: '35px !important',
  },
  stroke_width_Box: {
    display: 'grid !important',
    textTransform: 'none !important'
  },
  stroke_width_clr_field: {
    width: '35px'
  },
  clear_whiteBoard_field: {

  },
  remove_icon: {

  },
  textdisplay: {
    top: '46px',
    width: '240px',
    border: '1px solid #d6d6d6',
    height: '235px',
    position: 'absolute',
    background: '#fff',
    borderRadius: '10px',
    left: '-34px',
    padding: '10px',
  },
  textarea: {
    width: '96%',
    outline: 'none',
    background: '#f0f0f0',
    border: '1px solid #ccc',
    margin: '5px',
    padding: '5px',
    textAlign: 'left',
    verticalAlign: 'middle',
    color: '#000!important',
    fontSize: '120%',
    resize: 'none',
    borderRadius: '5px'
  },
  ctwo: {
    width: '99%',
    margin: '0px 0px 0px 0px!important',
    padding: '0px'
  },
  selecttwo: {
    background: '#fff',
    border: '1px solid #ccc',
    width: '96.5%',
    margin: '4px 5px 5px 5px',
    padding: '2px 5px',
    color: '#424242',
    fontSize: '100%',
    height: '32px',
    background: '#f0f0f0'
  },
  ctwoThree: {
    textAlign: 'left',
    margin: '2px 0px 9px 5px!important',
    padding: '0px'
  },
  atagSty: {
    color: '#424242',
    textDecoration: 'none',
    fontSize: '14px',
    marginLeft: '4px'
  },
  ctwoThreeButton: {
    textAlign: 'center',
    margin: '6px 0px 9px 5px!important',
    padding: '0px'
  }
}))

/** Pop-up Box */

function AlertDialog(props) {
  const { showPopup, setShowPopup, fabricCan } = props
  const [open, setOpen] = useState(showPopup)

  const handleClose = () => {
    setOpen(false)
    setShowPopup(false)
  }

  const handleClearWhiteboard = () => {
    props.clearWhiteboard(fabricCan)
    setOpen(false)
    setShowPopup(false)
  }

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle id='alert-dialog-title'>
          {'Do you want to clear the whiteboard ?'}
        </DialogTitle>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleClearWhiteboard} autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

/** Font Family Box */
function FontFamilyTool(props) {
  const { showFontFamily, fabricCan, setShowFontFamily, createText, getselectedTarget } = props;
  const classes = useStyles()
  const [fontFamilyData, setFontFamilyData] = useState({
    enterText: '',
    selectedTextStyle: '',
    selectedFontSize: 10
  })

  useEffect(() => {
    setFontFamilyData({
      enterText: getselectedTarget && getselectedTarget.text ? getselectedTarget.text : '',
      selectedTextStyle: getselectedTarget && getselectedTarget.fontFamily ? getselectedTarget.fontFamily : '',
      selectedFontSize: getselectedTarget && getselectedTarget.fontSize ? getselectedTarget.fontSize / 10 : 10
    })
  }, [getselectedTarget]);

  const textAreaValue = (event) => {
    setFontFamilyData({
      ...fontFamilyData,
      enterText: event.target.value
    })
  }

  const sizeInputText = (event) => {
    setFontFamilyData({
      ...fontFamilyData,
      selectedFontSize: event.target.value
    })
  }

  const handleChangeOption = (event) => {
    setFontFamilyData({
      ...fontFamilyData,
      selectedTextStyle: event.target.value
    })
  }

  const createTextSTL = () => {
    if (getselectedTarget && getselectedTarget.text) {
      getselectedTarget.set("text", fontFamilyData.enterText);
      getselectedTarget.set("fontFamily", fontFamilyData.selectedTextStyle);
      getselectedTarget.set("fontSize", fontFamilyData.selectedFontSize * 10);
      fabricCan.renderAll();
    } else {
      props.fontFamilyCallback(fontFamilyData);
      createText(fabricCan);
    }
    setShowFontFamily(!showFontFamily);
    setFontFamilyData({
      enterText: '',
      selectedTextStyle: '',
      selectedFontSize: 10
    })

  }

  return (
    <Popper className='toolbx' open={showFontFamily}>
      <div open={false} id="btnDisplayMenu" className={classes.textdisplay} style={{ display: 'block' }}>
        <textarea type="text" className={classes.textarea} placeholder="Enter Text Here." value={fontFamilyData.enterText} cols="4" rows="3" id="txtText" onChange={textAreaValue} />
        <div className={classes.ctwo}>
          <select id="txtStyle" className={classes.selecttwo} value={fontFamilyData.selectedTextStyle} onChange={handleChangeOption}>
            <option value="Times New Roman">Times New Roman</option>
            <option value="Arial">Arial</option>
            <option value="Monospace">Monospace</option>
            <option value="Cursive">Cursive</option>
            <option value="Fantasy">Fantasy</option>
            <option value="Pacifico">Pacifico</option>
          </select>
        </div>
        <div className={classes.ctwoThree}>
          <a className={classes.atagSty}>Size:</a><br />
          <input type="number" id="txtTextSize" value={fontFamilyData.selectedFontSize} onChange={sizeInputText} />
        </div>
        <div className={classes.ctwoThreeButton}>
          <Button variant="contained" onClick={() => createTextSTL(fabricCan)}>OK</Button>
        </div>
      </div>
    </Popper>
  )
}

/** Whiteboar toolbar start */
function Toolbar(props) {
  const {
    createShape,
    changeCurrentColor,
    changeStrokeWidth,
    fillAreaColor,
    backgroundColorShape,
    showDisebled,
    fillColorShow,
    showFillColorCheck,
    showClearWhiteboard,
    bgColorArea,
    viewer,
    optionsData,
    fabricCan
  } = props
  const [rectColor, setRectColor] = useState(false)
  const [cirColor, setCirColor] = useState(false)
  const [triangleColor, setTriangleColor] = useState(false)
  const [lineColor, setLineColor] = useState(false)
  const [pencileColor, setPencileColor] = useState(false)
  const [textColor, setTextColor] = useState(false)
  const [selectedComp, setSelectedComp] = useState(false)
  const classes = useStyles()

  const zoomImage = () => {
    var viewport = viewer.viewport
    viewport.zoomBy(1.1)
    viewport.applyConstraints()
    viewer && viewer.setMouseNavEnabled(true)
    removeCanvasListenerToolbar(fabricCan)
    fabricCan.isDrawingMode = false
  }
  const zoomOutImage = () => {
    var viewport = viewer.viewport
    viewport.zoomBy(0.9)
    viewport.applyConstraints()
    viewer && viewer.setMouseNavEnabled(true)
    removeCanvasListenerToolbar(fabricCan)
    fabricCan.isDrawingMode = false
  }
  const fullScreenPage = () => {
    viewer.setFullScreen(true)
    viewer && viewer.setMouseNavEnabled(true)
    removeCanvasListenerToolbar(fabricCan)
    fabricCan.isDrawingMode = false
  }

  const zoomSetHome = () => {
    var oldBounds = viewer.viewport.getBounds()
    var newBounds = new OpenSeadragon.Rect(
      -0.15,
      0.12,
      1.3,
      oldBounds.height / oldBounds.width
    )
    viewer.viewport.fitBounds(newBounds, true)
    viewer && viewer.setMouseNavEnabled(true)
    removeCanvasListenerToolbar(fabricCan)
    fabricCan.isDrawingMode = false
  }
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const [anchorEl2, setAnchorEl2] = React.useState(null);
  const open2 = Boolean(anchorEl2);
  const handleClick2 = (event) => {
    setAnchorEl(null);
    setAnchorEl2(event.currentTarget);
  };
  const handleClose2 = () => {
    setAnchorEl2(null);
  };

  function removeCanvasListenerToolbar (canvas) {
    canvas.off('mouse:down')
    canvas.off('mouse:move')
    canvas.off('mouse:up')
  }

  return (
    <div>
      <div className={classes.main_Box}>
        <label htmlFor='Home' className='lableIcon tooltip'>
          <HomeOutlinedIcon id='srch' />
          <span className='tooltiptext'>Home</span>
        </label>
        <input className='dnone' type='checkbox' id='Home' />
        <ul id='menu'>
          <li className='tooltip'>
              <HomeOutlinedIcon
                onClick={zoomSetHome}
                className={classes.rect_Icon}
              />
            <span className='tooltiptext'>Home</span>
          </li>
          <li className='tooltip'>
              <ZoomInOutlinedIcon
                onClick={zoomImage}
                className={classes.rect_Icon}
              />{' '}
            <span className='tooltiptext'>Zoom In</span>
          </li>
          <li className='tooltip'>
              {' '}
              <ZoomOutOutlinedIcon
                onClick={zoomOutImage}
                className={classes.rect_Icon}
              />{' '}
            <span className='tooltiptext'>Zoom Out</span>
          </li>
          <li className='tooltip'>
              {' '}
              <FullscreenIcon
                onClick={fullScreenPage}
                className={classes.rect_Icon}
              />{' '}
            <span className='tooltiptext'>Full Screen</span>
          </li>
        </ul>

        <Tooltip
          className='tooltip'
          title={<h6 className='toolfont'>Selection</h6>}
        >
          <Button
            className={
              selectedComp ? classes.shape_bgColor_Box : classes.shape_Box
            }
            onClick={() => {
              createShape('SELECT')
              setCirColor(false)
              setTriangleColor(false)
              setRectColor(false)
              setLineColor(false)
              setPencileColor(false)
              setTextColor(false)
              setSelectedComp(true)
            }}
          >
            <SelectionIcon style={{ marginLeft: '4px' }} />
          </Button>
        </Tooltip>

        <Button
          id='basic-button'
          aria-controls={open ? 'basic-menu' : undefined}
          aria-haspopup='true'
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClick}
        >
          <Tooltip
            className='tooltip'
            title={<h6 className='toolfont'>Shapes</h6>}
          >
            <ShapeLineOutlinedIcon />
          </Tooltip>
          {/* <img src={Shape} alt="" height="18px" /> */}
        </Button>
        <Menu
          id='basic-menu'
          className='shapes_icon'
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'basic-button'
          }}
        >
          <MenuItem onClick={handleClose}>
            <Tooltip
              className='tooltip'
              title={<h6 className='toolfont'>Rectangle</h6>}
            >
              <Button
                className={
                  rectColor ? classes.shape_bgColor_Box : classes.shape_Box
                }
                onClick={() => {
                  createShape('RECTANGLE')
                  setCirColor(false)
                  setTriangleColor(false)
                  setLineColor(false)
                  setPencileColor(false)
                  setTextColor(false)
                  setSelectedComp(false)
                  setRectColor(true)
                }}
              >
                <RectangleOutlinedIcon className={classes.rect_Icon} />
                {/* <img src={Rectangle} alt="" height="18px" /> */}
              </Button>
            </Tooltip>
            <Tooltip
              className='tooltip'
              title={<h6 className='toolfont'>Circle</h6>}
            >
              <Button
                className={
                  cirColor ? classes.shape_bgColor_Box : classes.shape_Box
                }
                onClick={() => {
                  createShape('CIRCLE')
                  setTriangleColor(false)
                  setLineColor(false)
                  setPencileColor(false)
                  setTextColor(false)
                  setRectColor(false)
                  setSelectedComp(false)
                  setCirColor(true)
                }}
              >
                <RadioButtonUncheckedIcon className={classes.circle_Icon} />
                {/* <img src={Circle} alt="" height="18px" /> */}
              </Button>
            </Tooltip>
            <Tooltip
              className='tooltip'
              title={<h6 className='toolfont'>Triangle</h6>}
            >
              <Button
                className={
                  triangleColor ? classes.shape_bgColor_Box : classes.shape_Box
                }
                onClick={() => {
                  createShape('TRIANGLE')
                  setLineColor(false)
                  setPencileColor(false)
                  setTextColor(false)
                  setRectColor(false)
                  setCirColor(false)
                  setSelectedComp(false)
                  setTriangleColor(true)
                }}
              >
                <ChangeHistoryIcon className={classes.triangle_Icon} />
                {/* <img src={Triangle} alt="" height="18px" /> */}
              </Button>
            </Tooltip>
            <Tooltip
              className='tooltip'
              title={<h6 className='toolfont'>Line</h6>}
            >
              <Button
                className={
                  lineColor ? classes.shape_bgColor_Box : classes.shape_Box
                }
                onClick={() => {
                  createShape('LINE')
                  setCirColor(false)
                  setTriangleColor(false)
                  setPencileColor(false)
                  setTextColor(false)
                  setRectColor(false)
                  setSelectedComp(false)
                  setLineColor(true)
                }}
              >
                <LinearScaleIcon className={classes.line_Icon} />
                {/* <img src={Line} alt="" height="20px" /> */}
              </Button>
            </Tooltip>
            <Tooltip
              className='tooltip'
              title={<h6 className='toolfont'>Pencil</h6>}
            >
              <Button
                className={
                  pencileColor ? classes.shape_bgColor_Box : classes.shape_Box
                }
                onClick={() => {
                  createShape('PENCIL')
                  setCirColor(false)
                  setTriangleColor(false)
                  setTextColor(false)
                  setRectColor(false)
                  setLineColor(false)
                  setSelectedComp(false)
                  setPencileColor(true)
                }}
              >
                <CreateIcon className={classes.pencil_Icon} />
                {/* <img src={pencil} alt="" height="20px" /> */}
              </Button>
            </Tooltip>
          </MenuItem>
        </Menu>
        <Tooltip className='tooltip' title={<h6 className='toolfont'>Text</h6>}>
          <Button
            className={
              textColor ? classes.shape_bgColor_Box : classes.shape_Box
            }
            onClick={() => {
              createShape('TEXT')
              setCirColor(false)
              setTriangleColor(false)
              setRectColor(false)
              setLineColor(false)
              setPencileColor(false)
              setSelectedComp(false)
              setTextColor(true)
            }}
          >
            <AbcIcon id='abc' className={classes.text_Icon} />
            {/* <img src={Text} alt="" height="15px" /> */}
          </Button>
        </Tooltip>

        <div className="clr_tool">
          <Tooltip
            className='tooltip'
            title={<h6 className='toolfont'>Stroke</h6>}
          >
            <Button className={classes.stroke_clr_Box}>
              <input
                className={classes.stroke_clr_Field}
                type='color'
                name='color'
                onChange={changeCurrentColor}
                onClick={() => {
                  setCirColor(false)
                  setTriangleColor(false)
                  setLineColor(false)
                  setPencileColor(false)
                  setTextColor(false)
                  setSelectedComp(false)
                  setRectColor(false)
                }}
                value={optionsData.currentColor}
              />
            </Button>
          </Tooltip>

          <Tooltip
            className='tooltip'
            title={<h6 className='toolfont'>Background Color</h6>}
          >
            <span>
            <Button
              className={classes.fill_clr_Box}
              // onClick={() => createShape('CLEAR')}
              disabled={!showFillColorCheck}
              onClick={() => {
                setCirColor(false)
                setTriangleColor(false)
                setLineColor(false)
                setPencileColor(false)
                setTextColor(false)
                setSelectedComp(false)
                setRectColor(false)
              }}
            >
              <input
                className="bgbox"
                type='color'
                name='color'
                value={bgColorArea}
                onChange={backgroundColorShape}
                disabled={!showFillColorCheck}
              // value="#235b76"
              />
            </Button>
            </span>
          </Tooltip>
          {/* <div className={classes.fill_clr_Box}>
            <input type='checkbox' name='checkbox' disabled={fillColorShow} onChange={fillAreaColor} />
            <label>Fill color</label>
          </div> */}

          <Tooltip
            className='tooltip'
            title={<h6 className='toolfont'>Color</h6>}
          >
            <span>
            <Button
              className={classes.fill_clr_Box}
              // onClick={() => createShape('CLEAR')}
              // disabled={fillColorShow}
              onClick={() => {
                setCirColor(false)
                setTriangleColor(false)
                setLineColor(false)
                setPencileColor(false)
                setTextColor(false)
                setSelectedComp(false)
                setRectColor(false)
              }}
            >
              <input
                type='checkbox'
                name='checkbox'
                // disabled={fillColorShow}
                onChange={fillAreaColor}
                style={{ width: '20px', height: '20px' }}
              />
            </Button>
            </span>
          </Tooltip>

          <Tooltip
            className='tooltip'
            title={<h6 className='toolfont'>Stroke width</h6>}
          >
            <Button
              className={classes.stroke_width_Box}
              onClick={() => {
                setCirColor(false)
                setTriangleColor(false)
                setLineColor(false)
                setPencileColor(false)
                setTextColor(false)
                setSelectedComp(false)
                setRectColor(false)
              }}
            >
              <input
                className={classes.stroke_width_clr_field}
                type='range'
                name='storkewidth'
                onChange={changeStrokeWidth}
                min={1}
                max={10}
                defaultValue={2}
              />
            </Button>
          </Tooltip>

          <Tooltip
            className='tooltip'
            title={<h6 className='toolfont'>Measure Distance</h6>}
          >
            <Button
              className={classes.shape_Box}
              onClick={() => {
                createShape('MEASUREMENT')
                setCirColor(false)
                setTriangleColor(false)
                setRectColor(false)
                setLineColor(false)
                setPencileColor(false)
                setTextColor(false)
                setSelectedComp(false)
              }}
            >
              <StraightenIcon id='mgnt' className={classes.rect_Icon} />
            </Button>
          </Tooltip>

          <Tooltip
            className='tooltip'
            title={<h6 className='toolfont'>Shape</h6>}
          >
            <span>
            <Button
              className={classes.shape_Box}
              onClick={() => createShape('REMOVE')}
              disabled={showDisebled}
            >
              <RemoveCircleOutlineOutlinedIcon
                className={classes.remove_icon}
              />
            </Button>
            </span>
          </Tooltip>

          <Tooltip
            className='tooltip'
            title={<h6 className='toolfont'>Whiteboard</h6>}
          >
            <span>
            <Button
              className={classes.shape_Box}
              onClick={() => {
                createShape('CLEAR')
                setCirColor(false)
                setTriangleColor(false)
                setLineColor(false)
                setPencileColor(false)
                setTextColor(false)
                setSelectedComp(false)
                setRectColor(false)
              }}
              disabled={showClearWhiteboard}
            >
              <DeleteOutlineOutlinedIcon
                id='trsh'
                className={classes.clear_whiteBoard_field}
              />
            </Button>
            </span>
          </Tooltip>
        </div>
      </div>
    </div>
  )
}
/** Whiteboar toolbar end */

let drawInstance = null
let cursor = null
let origX
let origY
let mouseDown = false
let measureCoords = {}

const modes = {
  RECTANGLE: 'RECTANGLE',
  TRIANGLE: 'TRIANGLE',
  ELLIPSE: 'CIRCLE',
  LINE: 'LINE',
  PENCIL: 'PENCIL',
  SELECT: 'SELECT'
}

function stopDrawing() {
  mouseDown = false
}

/** Main Component */
export default function WhiteboardAnnotation(props) {
  const { openSeaDragonOptions, viewerContainerWidth, selectedId, setProcessedViewer } = props

  if (!viewerContainerWidth) {
    viewerContainerWidth = { width: 1000, height: 1000 }
  }

  const defaultStrokeWidth = 15
  const defaultFontSize = 130
  const options = {
    currentMode: '',
    currentColor: '#000000',
    currentWidth: defaultStrokeWidth,
    fill: false,
    BgColor: '#000000',
    group: {},
    fontSize: defaultFontSize
  }

  const [viewer, setViewer] = useState(null)
  const [fabricCan, setFabricCan] = useState(null)
  const [showPopup, setShowPopup] = useState(false)
  const [showDisebled, setShowDisebled] = useState(true)
  const [fillColorShow, setFillColorShow] = useState(true)
  const [showClearWhiteboard, setShowClearWhiteboard] = useState(true)
  const [bgColorArea, setBgColorArea] = useState('')
  const [getselectedTarget, setGetselectedTarget] = useState(null)
  const [showFillColorCheck, setShowFillColorCheck] = useState(null)
  const [optionsData, setOptionsData] = useState(options)
  const [showFontFamily, setShowFontFamily] = useState(false)
  let fontFamilySTLData = null

  const dispatch = useDispatch();
  const user_id = Cookies.get('user_id');
  const org_id = Cookies.get('org_id');
  const dataAnnotations = useSelector((state) => {
    return state.fetchImageData.data;
  })

  /** Change stroke color of selected shape */
  function selectedChangeStrokeColor(canvas) {
    if (canvas && canvas.getActiveObject()) {
      canvas.getActiveObject().set('stroke', `${options.currentColor}`)
      if (canvas && canvas.getActiveObject().text) {
        canvas.getActiveObject().set("fill", `${options.currentColor}`);
      }
      canvas.renderAll()
    }
  }

  /** Change color of shape */
  function changeCurrentColor(event) {
    options.currentColor = event.target.value
    setOptionsData({
      ...optionsData,
      currentColor: event.target.value
    })
    if (fabricCan) {
      fabricCan.freeDrawingBrush.color = options.currentColor
      selectedChangeStrokeColor(fabricCan)
    }
    removeCanvasListener(fabricCan);
  }

  /** Change stroke width of selected shape */
  function selectedChangeStrokeWidth(canvas) {
    if (canvas && canvas.getActiveObject()) {
      canvas
        .getActiveObject()
        .set('strokeWidth', Math.round(`${options.currentWidth}`))
      canvas.getActiveObject().set('fontSize', `${options.fontSize}`)
      canvas.renderAll()
    }
  }

  /** Change stroke of shape */
  function changeStrokeWidth(event) {
    options.currentWidth = (+event.target.value / 2) * defaultStrokeWidth
    setOptionsData({
      ...optionsData,
      currentWidth: (+event.target.value / 2) * defaultStrokeWidth
    })
    if (fabricCan) {
      fabricCan.freeDrawingBrush.width = options.currentWidth
      selectedChangeStrokeWidth(fabricCan)
    }
    options.fontSize = (+event.target.value / 3) * defaultFontSize
    removeCanvasListener(fabricCan);
  }

  /** Change background color of selected shape */
  function selectedChangeBgColor(canvas) {
    if (canvas && canvas.getActiveObject()) {
      canvas.getActiveObject().set('fill', `${options.BgColor}`)
      canvas.renderAll()
    }
  }

  /** BG color of shape */
  function backgroundColorShape(event) {
    options.BgColor = event.target.value
    setOptionsData({
      ...optionsData,
      BgColor: event.target.value
    })
    setBgColorArea(event.target.value)
    selectedChangeBgColor(fabricCan)
    removeCanvasListener(fabricCan);
  }

  /** Remove the shape by key */
  function removeShapeWithKey(canvas) {
    document.addEventListener('keydown', e => {
      if (e.key === 'Backspace') {
        canvas.remove(canvas.getActiveObject())
      }
    })
  }

  /** Get selected object */
  function getSelectedObject(canvas) {
    if (canvas.target) {
      setGetselectedTarget(canvas.target)
      setShowDisebled(false)
    } else {
      setGetselectedTarget(null)
      setShowDisebled(true)
    }
  }

  const selectAll = canvas => {
    if (!canvas) return
    canvas.isDrawingMode = false
    removeCanvasListener(canvas)
    canvas.discardActiveObject()
    canvas.requestRenderAll()
    viewer && viewer.setMouseNavEnabled(false)
    canvas.getObjects().map(item => item.set({ selectable: true }))
    // setFillColorShow(true);
    // removeShapeWithKey(canvas);
    canvas.on('mouse:up', getSelectedObject)
    canvas.on('mouse:up', () => {
      const data = canvas.toJSON();
      saveData(data);
      stopDrawing();
    })
  }

  function fillAreaColor(event) {
    options.fill = event.target.checked
    setOptionsData({
      ...optionsData,
      fill: event.target.checked
    })
    setShowFillColorCheck(event.target.checked)
    // canvas.getActiveObject().set('fill', `${options.BgColor}`)
    if (!event.target.checked && fabricCan && fabricCan.getActiveObject()) {
      fabricCan.getActiveObject().set('fill', `transparent`);
      fabricCan.renderAll()
    }else if (event.target.checked && fabricCan && fabricCan.getActiveObject()) {
      fabricCan.getActiveObject().set('fill', optionsData.BgColor);
      fabricCan.renderAll()
    }
    removeCanvasListener(fabricCan);
  }

  function createShape(shape) {
    if (fabricCan) {
      fabricCan.getObjects().map((item) => {
        if (item && item.meterLine) {
          fabricCan.remove(item);
        }
      });
      console.log('fabric canvas is ready')
      switch (shape) {
        case 'CIRCLE':
          createEllipse(fabricCan)
          break
        case 'TRIANGLE':
          createTriangle(fabricCan)
          break
        case 'LINE':
          createLine(fabricCan)
          break
        case 'TEXT':
          // createText(fabricCan)
          setShowFontFamily(!showFontFamily)
          break
        case 'PENCIL':
          createPencil(fabricCan)
          break
        case 'RECTANGLE':
          createRectangle(fabricCan)
          break
        case 'SELECT':
          selectAll(fabricCan)
          break
        case 'CLEAR':
          clearBoard(fabricCan)
          break
        case 'REMOVE':
          removeParticularShape(fabricCan)
          break
        case 'MEASUREMENT':
          createLineMeasure(fabricCan)
          // createTextMeasure(fabricCan)
          // measureDistanceCell(fabricCan)
          break
        default:
          console.log('default one')
      }
    } else {
      console.log('fabric canvas is not ready')
    }
  }

  function removeCanvasListener(canvas) {
    canvas.off('mouse:down')
    canvas.off('mouse:move')
    canvas.off('mouse:up')
  }

  /** Clear whiteboard confirmation popup */
  function clearBoard(canvas) {
    if (canvas && canvas._objects.length > 0) {
      setShowPopup(true)
    }
  }

  /** Clear whiteboard */
  function clearWhiteboard(canvas) {
    if (canvas && canvas._objects.length > 0) {
      canvas.clear()
      setShowClearWhiteboard(true)
      const data = canvas.toJSON();
      saveData(data);
    }
  }

  /** Remove particular shape */
  function removeParticularShape(canvas) {
    if (canvas && canvas._objects.length > 0) {
      canvas.remove(canvas.getActiveObject())
      const data = canvas.toJSON();
      saveData(data);
    }
    if (canvas && canvas._objects.length === 0) {
      setShowClearWhiteboard(true)
    }
    setShowDisebled(true)
  }

  function showBoardIcon(canvas) {
    if (canvas) {
      setShowClearWhiteboard(false)
    }
  }

  /* === Rectangle start === */
  function createRectangle(canvas) {
    if (options.currentMode !== modes.RECTANGLE) {
      options.currentMode = modes.RECTANGLE
      removeCanvasListener(canvas)
      canvas.on('mouse:down', startAddRectangle(canvas))
      canvas.on('mouse:move', startDrawRectangle(canvas))
      canvas.on('mouse:up', () => {
        const data = canvas.toJSON();
        saveData(data);
        stopDrawing();
      })
      canvas.on('mouse:up', showBoardIcon)
      canvas.selection = false
      canvas.hoverCursor = 'auto'
      canvas.isDrawingMode = false
      canvas.getObjects().map(item => item.set({ selectable: false }))
      canvas.discardActiveObject().requestRenderAll()
      if (canvas) {
        setFillColorShow(false)
      }
      if (modes.TRIANGLE && optionsData.fill) {
        setShowFillColorCheck(true)
      }
    }
  }

  function startAddRectangle(canvas) {
    return ({ e }) => {
      mouseDown = true
      options.currentMode = modes.RECTANGLE
      viewer && viewer.setMouseNavEnabled(false)
      const pointer = canvas.getPointer(e)
      origX = pointer.x
      origY = pointer.y
      drawInstance = new fabric.Rect({
        stroke: optionsData.currentColor,
        strokeWidth: optionsData.currentWidth,
        fill: optionsData.fill ? optionsData.BgColor : 'transparent',
        left: origX,
        top: origY,
        width: 0,
        height: 0,
        selectable: false
      })

      canvas.add(drawInstance)
    }
  }

  function startDrawRectangle(canvas) {
    return ({ e }) => {
      if (mouseDown) {
        const pointer = canvas.getPointer(e)
        console.log('org ', origX, origY)
        console.log('pointer ', pointer.x, pointer.y)
        if (pointer.x < origX) {
          console.log('set towards the top')
          drawInstance.set('left', pointer.x)
        }
        if (pointer.y < origY) {
          console.log('set towards the top')
          drawInstance.set('top', pointer.y)
        }
        drawInstance.set({
          width: Math.abs(pointer.x - origX),
          height: Math.abs(pointer.y - origY)
        })

        drawInstance.setCoords()
        canvas.renderAll()
      }
    }
  }

  /* === Rectangle finish === */

  /* === Triangle start === */
  function createTriangle(canvas) {
    if (options.currentMode !== modes.TRIANGLE) {
      options.currentMode = modes.TRIANGLE
      removeCanvasListener(canvas)
      canvas.on('mouse:down', startAddTriangle(canvas))
      canvas.on('mouse:move', startDrawingTriangle(canvas))
      canvas.on('mouse:up', () => {
        const data = canvas.toJSON();
        saveData(data);
        stopDrawing();
      })
      canvas.on('mouse:up', showBoardIcon)
      canvas.selection = false
      canvas.hoverCursor = 'auto'
      canvas.isDrawingMode = false
      canvas.getObjects().map(item => item.set({ selectable: false }))
      canvas.discardActiveObject().requestRenderAll()
      if (modes.TRIANGLE) {
        setFillColorShow(false)
      }
      if (modes.TRIANGLE && optionsData.fill) {
        setShowFillColorCheck(true)
      }
    }
  }

  function startAddTriangle(canvas) {
    return ({ e }) => {
      mouseDown = true
      options.currentMode = modes.TRIANGLE
      viewer && viewer.setMouseNavEnabled(false)
      const pointer = canvas.getPointer(e)
      origX = pointer.x
      origY = pointer.y
      drawInstance = new fabric.Triangle({
        stroke: optionsData.currentColor,
        strokeWidth: optionsData.currentWidth,
        fill: optionsData.fill ? optionsData.BgColor : 'transparent',
        left: origX,
        top: origY,
        width: 0,
        height: 0,
        selectable: false
      })

      canvas.add(drawInstance)
    }
  }

  function startDrawingTriangle(canvas) {
    return ({ e }) => {
      if (mouseDown) {
        const pointer = canvas.getPointer(e)
        if (pointer.x < origX) {
          drawInstance.set('left', pointer.x)
        }
        if (pointer.y < origY) {
          drawInstance.set('top', pointer.y)
        }
        drawInstance.set({
          width: Math.abs(pointer.x - origX),
          height: Math.abs(pointer.y - origY)
        })
        drawInstance.setCoords()
        canvas.renderAll()
      }
    }
  }

  /* === Triangle finish === */

  /* ==== Circle Start ==== */
  function createEllipse(canvas) {
    if (options.currentMode !== modes.ELLIPSE) {
      options.currentMode = modes.ELLIPSE
      removeCanvasListener(canvas)
      canvas.on('mouse:down', startAddEllipse(canvas))
      canvas.on('mouse:move', startDrawingEllipse(canvas))
      canvas.on('mouse:up', () => {
        const data = canvas.toJSON();
        saveData(data);
        stopDrawing();
      })
      canvas.on('mouse:up', showBoardIcon)
      canvas.selection = false
      canvas.hoverCursor = 'auto'
      canvas.isDrawingMode = false
      canvas.getObjects().map(item => item.set({ selectable: false }))
      canvas.discardActiveObject().requestRenderAll()
      if (modes.ELLIPSE) {
        setFillColorShow(false)
      }
      if (modes.TRIANGLE && optionsData.fill) {
        setShowFillColorCheck(true)
      }
    }
  }

  function startAddEllipse(canvas) {
    return ({ e }) => {
      viewer && viewer.setMouseNavEnabled(false)
      mouseDown = true
      const pointer = canvas.getPointer(e)
      origX = pointer.x
      origY = pointer.y
      drawInstance = new fabric.Ellipse({
        stroke: optionsData.currentColor,
        strokeWidth: optionsData.currentWidth,
        fill: optionsData.fill ? optionsData.BgColor : 'transparent',
        left: origX,
        top: origY,
        cornerSize: 7,
        objectCaching: false,
        selectable: false
      })
      canvas.add(drawInstance)
    }
  }

  function startDrawingEllipse(canvas) {
    return ({ e }) => {
      if (mouseDown) {
        console.log('viewer on mouse move ', viewer)
        viewer && viewer.setMouseNavEnabled(false)
        // viewer.setMouseNavEnabled(true);
        const pointer = canvas.getPointer(e)
        if (pointer.x < origX) {
          drawInstance.set('left', pointer.x)
        }
        if (pointer.y < origY) {
          drawInstance.set('top', pointer.y)
        }
        drawInstance.set({
          rx: Math.abs(pointer.x - origX) / 2,
          ry: Math.abs(pointer.y - origY) / 2
        })
        drawInstance.setCoords()
        canvas.renderAll()
      }
    }
  }
  /* ==== Circle Finish ==== */

  /*  ==== Shape Line Start  ==== */
  function createLine(canvas) {
    if (modes.currentMode !== modes.LINE) {
      options.currentMode = modes.LINE
      removeCanvasListener(canvas)
      canvas.on('mouse:down', startAddLine(canvas))
      canvas.on('mouse:move', startDrawingLine(canvas))
      canvas.on('mouse:up', () => {
        const data = canvas.toJSON();
        saveData(data);
        stopDrawing();
      })
      canvas.on('mouse:up', showBoardIcon)
      canvas.selection = false
      canvas.hoverCursor = 'auto'
      canvas.isDrawingMode = false
      canvas.getObjects().map(item => item.set({ selectable: false }))
      canvas.discardActiveObject().requestRenderAll()
      if (canvas) {
        setFillColorShow(true)
        setShowFillColorCheck(false)
      }
    }
  }

  function startAddLine(canvas) {
    return ({ e }) => {
      viewer && viewer.setMouseNavEnabled(false)
      mouseDown = true
      let pointer = canvas.getPointer(e)
      drawInstance = new fabric.Line(
        [pointer.x, pointer.y, pointer.x, pointer.y],
        {
          strokeWidth: optionsData.currentWidth,
          stroke: optionsData.currentColor,
          selectable: false
        }
      )
      canvas.add(drawInstance)
      canvas.requestRenderAll()
    }
  }

  function startDrawingLine(canvas) {
    return ({ e }) => {
      if (mouseDown) {
        const pointer = canvas.getPointer(e)
        drawInstance.set({
          x2: pointer.x,
          y2: pointer.y
        })
        drawInstance.setCoords()
        canvas.requestRenderAll()
      }
    }
  }
  /*  ==== Shape Line finish  ==== */

  function stopDrawingMeasure(canvas) {
    return ({ e }) => {
      mouseDown = false
      const x1 = measureCoords.x ? Number(measureCoords.x).toFixed(4) : null;
      const x2 = e.x ? Number(e.x).toFixed(4) : null;
      const y1 = measureCoords.y ? Number(measureCoords.y).toFixed(4) : null;
      const y2 = e.y ? Number(e.y).toFixed(4) : null;
      const totalDistancePixel = (Math.sqrt(Math.pow((x2 - x1), 2) + (Math.pow((y2 - y1), 2)))).toFixed(4);
      const getDistanceMM = 1.2;
      const getDistancePixel = viewerContainerWidth.width;
      const finalValueMSR = (getDistanceMM * totalDistancePixel) / getDistancePixel;
      const finalValue = (finalValueMSR ? finalValueMSR.toFixed(4) : '')
      cursor.set({
        left: drawInstance.x2 * 1.01,
        top: drawInstance.y2 * 1.01
      });
      cursor.set({
        text: `${finalValue}`
      });
      canvas.requestRenderAll();
    }
  }
  /* ==== Create line for measure distance ==== */
  function createLineMeasure(canvas) {
    if (modes.currentMode !== modes.LINE) {
      options.currentMode = modes.LINE
      removeCanvasListener(canvas)
      canvas.on('mouse:down', startAddLineMeasure(canvas))
      canvas.on('mouse:move', startDrawingLineMeasure(canvas))
      canvas.on('mouse:up', stopDrawingMeasure(canvas))
      canvas.selection = false
      canvas.hoverCursor = 'auto'
      canvas.isDrawingMode = false
      canvas.getObjects().map(item => item.set({ selectable: false }))
      canvas.discardActiveObject().requestRenderAll()
      if (canvas) {
        setFillColorShow(true)
        setShowFillColorCheck(false)
      }
    }
  }

  function startAddLineMeasure(canvas) {
    return ({ e }) => {
      viewer && viewer.setMouseNavEnabled(false)
      mouseDown = true
      canvas.getObjects().map((item) => {
        if (item && item.meterLine) {
          canvas.remove(item);
        }
      });
      let pointer = canvas.getPointer(e)
      drawInstance = new fabric.Line(
        [pointer.x, pointer.y, pointer.x, pointer.y],
        {
          strokeWidth: 10,
          stroke: 'red',
          selectable: false,
          meterLine: 'Test',
        }
      )
      measureCoords.x = e.x;
      measureCoords.y = e.y;

      canvas.add(drawInstance)
      canvas.requestRenderAll()
      cursor = new fabric.Text('', {
        fill: '#000000',
        editable: false,
        fontSize: optionsData.fontSize,
        fontFamily: 'Roboto, Helvetica, Arial, sans-serif'
      });
      cursor.meterLine = 'Text'
      cursor.lockScalingX = true;
      cursor.lockScalingY = true;
      cursor.lockUniScaling = true;
      cursor.textBackgroundColor = "#ccc";
      canvas.add(cursor);
      canvas.requestRenderAll()
    }
  }

  function startDrawingLineMeasure(canvas) {
    return ({ e }) => {
      if (mouseDown) {
        const pointer = canvas.getPointer(e)
        drawInstance.set({
          x2: pointer.x,
          y2: pointer.y
        })
        drawInstance.setCoords()
        canvas.requestRenderAll()
      }
    }
  }
  /* ==== End measure distance ==== */

  // Text
  function createText(canvas) {
    removeCanvasListener(canvas)
    if (canvas) {
      setFillColorShow(true)
      setShowClearWhiteboard(false)
    }
    viewer && viewer.setMouseNavEnabled(false)
    canvas.isDrawingMode = false
    const text = new fabric.Textbox(`${fontFamilySTLData.enterText}`, {
      left: 300,
      top: 300,
      fill: optionsData.currentColor,
      editable: true,
      selectable: false,
      fontSize: fontFamilySTLData.selectedFontSize * 10,
      fontFamily: fontFamilySTLData.selectedTextStyle,
      // fontStyle: "oblique",
    })
    canvas.add(text)
    canvas.renderAll()
    const data = canvas.toJSON();
    saveData(data);
  }

  // Pencil
  function createPencil(canvas) {
    viewer && viewer.setMouseNavEnabled(false)
    if (canvas) {
      setFillColorShow(true)
      setShowFillColorCheck(false)
    }
    canvas.on('mouse:up', showBoardIcon)
    if (
      options.currentMode !== modes.PENCIL &&
      options.currentMode != modes.SELECT
    ) {
      removeCanvasListener(canvas)
      options.currentMode = modes.PENCIL
      canvas.freeDrawingBrush.width =
        parseInt(optionsData.currentWidth, 10) || 1
      canvas.freeDrawingBrush.color = optionsData.currentColor
      canvas.isDrawingMode = true
      canvas.on('mouse:up', () => {
        const data = canvas.toJSON();
        saveData(data);
        showBoardIcon();
      })
    }
  }

  useEffect(() => {
    if (!openSeaDragonOptions) {
      console.log('openSeaDragon option(openSeaDragonOptions) is required')
      alert('openSeaDragon option(openSeaDragonOptions) is required')
    }
    initFabricJSOverlay(OpenSeadragon, fabric)
    const openSeadragonViewer = OpenSeadragon(openSeaDragonOptions)
    setViewer(openSeadragonViewer)
    const overlay = openSeadragonViewer.fabricjsOverlay({ scale: 1 })
    const fabricCanvas = overlay.fabricCanvas()
    setProcessedViewer(openSeadragonViewer);
    setFabricCan(fabricCanvas);
  }, [openSeaDragonOptions]);

  const fontFamilyCallback = (data) => {
    fontFamilySTLData = data;
  }

  useEffect(() => {
    if (dataAnnotations !== undefined && dataAnnotations.length > 0) {
      const added = dataAnnotations[0].meta?.annotations;
      if (added) {
        fabricCan.loadFromJSON(added);
        fabricCan.renderAll();
      }
      if (fabricCan && fabricCan._objects.length > 0) {
        fabricCan.hoverCursor = 'auto';
        fabricCan.getObjects().map(item => item.set({ selectable: false }));
        viewer && viewer.setMouseNavEnabled(false);
        setShowClearWhiteboard(false);
      }
    }
  }, [dataAnnotations]);

  const saveData = (data) => {
    dispatch(saveAnnotationData(org_id, user_id, data, selectedId));
  };

  return (
    <div>
      <Toolbar
        createShape={createShape}
        changeCurrentColor={changeCurrentColor}
        changeStrokeWidth={changeStrokeWidth}
        fillAreaColor={fillAreaColor}
        backgroundColorShape={backgroundColorShape}
        fabricCan={fabricCan}
        showDisebled={showDisebled}
        fillColorShow={fillColorShow}
        showFillColorCheck={showFillColorCheck}
        showClearWhiteboard={showClearWhiteboard}
        bgColorArea={bgColorArea}
        viewer={viewer}
        optionsData={optionsData}
      />
      <FontFamilyTool
        showFontFamily={showFontFamily}
        fabricCan={fabricCan}
        setShowFontFamily={setShowFontFamily}
        createText={createText}
        fontFamilyCallback={fontFamilyCallback}
        getselectedTarget={getselectedTarget}
      />
      {showPopup && (
        <AlertDialog
          showPopup={showPopup}
          setShowPopup={setShowPopup}
          clearWhiteboard={clearWhiteboard}
          fabricCan={fabricCan}
        />
      )}
      {props.children}
    </div>
  )
}