import OpenSeaDragon from "openseadragon";
import { useEffect, useState } from 'react';

import DownIcon from '@mui/icons-material/KeyboardArrowDown';
import UpIcon from '@mui/icons-material/KeyboardArrowUp';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { green } from '@mui/material/colors';
import Fab from '@mui/material/Fab';

import { CheckBoxOutlineBlankOutlined, CheckBoxOutlined } from "@mui/icons-material";
import { ToggleButton, Tooltip, Typography } from '@mui/material';
import image from 'theme/assets/icon.png';
import { useAlgorithms, useImageEngine, useWindowDimensions } from "../../../hooks";
import WhiteboardAnnotation from './WhiteboardAnnotation.js';

const ImageViewer = (props) => {
    const { selectedId, selectedProcessedImage } = props; // how to get the image?
    const [viewer, setViewer] = useState();
    const [annotationOpen, setAnnotationOpen] = useState(false);
    const [displayAlt, setDisplayAlt] = useState(false);
    const [lastUri, setLastUri] = useState("");
    const [theMeta, setTheMeta] = useState(); // metadata
    const [processedViewer, setProcessedViewer] = useState({});
    const [whiteboardOptions, setWhiteboardOptions] = useState(
        {
            id: "diz_openSeaDragon_annotation",
            animationTime: 0.5,
            blendTime: 0.1,
            constrainDuringPan: true,
            maxZoomPixelRatio: 2,
            minZoomLevel: 1,
            visibilityRatio: 1,
            zoomPerScroll: 2,
            timeout: 600000,
            tileSources: '',
            showNavigationControl: false
          }
    );

    const { width, height } = useWindowDimensions();
    const { activeImage } = useImageEngine(); // use the imageEngine to get the active image, eventually depends on redux/ImageEngine.js api calls
    const { algoNameForId } = useAlgorithms(); 
    
    const fabStyle = {
        position: 'absolute',
        bottom: annotationOpen ? 125 : 50,
        right: 16,
        color: 'common.white',
        bgcolor: green[500],
        '&:hover': {
            bgcolor: green[600],
        },
    };

    const viewStyle = {
        width: width * 0.579,
        height: height * 0.9,
        // backgroundImage: `url(${image})`,
    }

    // const getImages = async () => {
    //     await requestImagesFromServerAsync();
    // };

    useEffect(() => {
        // getImages();
        return () => {
            viewer && viewer.destroy();
        };
    }, []); //eslint-disable-line
    
    useEffect(() => {
        if (activeImage && activeImage.uri) { 
            const storageName = window.env.REACT_APP_STORAGE_NAME
            if (activeImage.uri !== lastUri) {
                setLastUri(activeImage.uri)
                setDisplayAlt(false)
                setTheMeta(activeImage.meta)
            }
            viewer && viewer.destroy();
            setViewer(
                OpenSeaDragon({
                id: "openSeaDragon",
                prefixUrl: "openseadragon-images/",
                animationTime: 0.5,
                blendTime: 0.1,
                constrainDuringPan: true,
                maxZoomPixelRatio: 2,
                minZoomLevel: 1,
                visibilityRatio: 1,
                zoomPerScroll: 2,
                showNavigator: true,
                timeout: 600000,
                tileSources: {
                    type: 'image',
                    url: displayAlt ? `https://${storageName}.blob.core.windows.net${activeImage.uri}` : `https://${storageName}.blob.core.windows.net${activeImage.sample_image_location}`,
                    buildPyramid: true,
                }
            }));
        }
        console.log(activeImage, selectedId) // test the active image when not selected 
    }, [activeImage, displayAlt]); //eslint-disable-line

    let imgUrl = '';
    if(selectedProcessedImage) {
        const selectedStain = selectedProcessedImage.stain_list.split(',')[0];
        const fileName = `${selectedProcessedImage.sample_name.split(".")[0]} .dzi`;
        imgUrl = `${selectedProcessedImage.uri}/vIHC ${selectedStain}/${fileName}`;
    }

    useEffect(() => {
    if(selectedProcessedImage !== undefined && Object.values(selectedProcessedImage).length > 0){
        processedViewer && processedViewer.destroy();
        const openSeaDragonOptions = {
            id: "diz_openSeaDragon_annotation",
            prefixUrl: "openseadragon-images/",
            animationTime: 0.5,
            blendTime: 0.1,
            constrainDuringPan: true,
            maxZoomPixelRatio: 2,
            minZoomLevel: 1,
            visibilityRatio: 1,
            zoomPerScroll: 2,
            timeout: 600000,
            tileSources: imgUrl,
            showNavigationControl: false
            };
        setWhiteboardOptions(openSeaDragonOptions);
    }

    }, [selectedProcessedImage, imgUrl]);

    const viewerContainerWidth = {width: 1150, height: 700}
 

    return (
        <div>
            <WhiteboardAnnotation openSeaDragonOptions={whiteboardOptions} setProcessedViewer={setProcessedViewer} viewerContainerWidth={viewerContainerWidth} selectedId={selectedId}>
            {activeImage && (
                <ToggleButton
                    color="secondary"
                    value="displayProcessed" 
                    selected={displayAlt} 
                    sx={{position: 'absolute', zIndex: 5, marginTop: 5, textTransform: 'none'}} 
                    onChange={() => setDisplayAlt(!displayAlt)}>
                    {!displayAlt ? (<CheckBoxOutlineBlankOutlined />) : (<CheckBoxOutlined />)} <Typography>Display Prediction</Typography>
                </ToggleButton>)
            }
            {annotationOpen ? (
                <Card variant="outlined" sx={{height: 150, width: width * 0.579, position: 'absolute', bottom: 0, zIndex: 5}}>
                    <CardContent>
                        <Typography variant='h5'>
                            {activeImage.sample_name}
                        </Typography>
                        <Typography variant='body2'>
                            Algorithm: {algoNameForId(activeImage.algo_id)}
                        </Typography>
                        {theMeta && activeImage && (
                            <div>
                                <Typography variant='body2'>
                                    Predicted Pos: {activeImage.meta.pos}
                                </Typography>
                                <Typography variant='body2'>
                                    Predicted Neg: {activeImage.meta.neg}
                                </Typography>
                                <Typography variant='body2'>
                                    Predicted Index: {activeImage.meta.index}
                                </Typography>
                                <Typography variant='body2'>
                                    Stain List: {activeImage.stain_list}
                                </Typography>
                            </div>)}
                    </CardContent>
                </Card>
            ) : (
                activeImage && (<Card variant="outlined" sx={{width: width * 0.579, position: 'absolute', bottom: 0, zIndex: 5}}>
                    <CardContent>
                        <Typography variant='h5'>
                            {activeImage.sample_name}
                        </Typography>
                    </CardContent>
                </Card>)
            )}
            {/* <div 
                id="openSeaDragon" 
                style={viewStyle}
                >
            </div> */}
            <div id="diz_openSeaDragon_annotation"style={viewStyle}></div>
            {activeImage && (<Fab sx={fabStyle} aria-label={'annotations'} onClick={() => setAnnotationOpen(!annotationOpen)}>
                <Tooltip title={annotationOpen ? "Close Details" : "Open Details"} placement="left">
                    {
                        annotationOpen ? ( 
                            <DownIcon />
                        ): (
                            <UpIcon />
                        )
                    }
                </Tooltip>
            </Fab>)}
            </WhiteboardAnnotation>
        </div>
    );
}

export default ImageViewer;