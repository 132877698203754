import axios from 'axios';
export async function saveVisitors(data: any, headers: HeadersInit = {}): Promise<any> {
    try {
        let serverUrl = '';
        if (typeof window.env !== "undefined") {
            serverUrl = window.env.REACT_APP_API_TARGET_URL;    
        }
        const response = await fetch(`${serverUrl}/api/1.0.0/visitors`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          ...headers,
        },
        body: JSON.stringify(data),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
    } catch (error) {
      console.error('There was an error!', error);
      throw error;
    }
  }

  export async function geolocationInfo(): Promise<any> {
    try {
        const response = await fetch('https://ipapi.co/json/');
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const responseData = await response.json();
        const responseInfo = {
            version: responseData.version,
            city:  responseData.city,
            region: responseData.region,
            country_name: responseData.country_name,
            country_capital: responseData.country_capital,
            postal: responseData.postal,
            latitude: responseData.latitude,
            longitude: responseData.longitude,
            timezone: responseData.timezone,
            org: responseData.org
          }
        return {responseInfo: responseInfo, ip_address: responseData.ip};
    } catch (error) {
      console.error('There was an error!', error);
      throw error;
    }
  }

  export async function getUserInfo(token) {
    try {
      const response = await axios.get('https://www.googleapis.com/oauth2/v3/userinfo', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data;
    } catch (error) {
      console.error('Error fetching user info:', error);
    }
  }

  export async function ssoVisitorsLogs (data, token) {
    const user = await getUserInfo(token)
    const locationData = await geolocationInfo();
    try {
      const visitorsData = {
        username: user.email || '',
        ip_address: locationData.ip_address,
        geolocation: JSON.stringify(locationData.responseInfo),
        status: data,
        category: "sso"
      }
      saveVisitors(visitorsData);
    } catch (err) {
      console.log(err);
    }
  };

  // add visitors logs landing over the application
  export async function landingVisitorsLogs () {
    const locationData = await geolocationInfo();
    try {
      const visitorsData = {
        ip_address: locationData.ip_address,
        geolocation: JSON.stringify(locationData.responseInfo),
        category: "landing"
      }
      saveVisitors(visitorsData);
    } catch (err) {
      console.log(err);
    }
  };
  