import Button from '@material-ui/core/Button';
import LinearProgress from '@material-ui/core/LinearProgress';
import withStyles from '@material-ui/core/styles/withStyles';
import AuthCard from 'components/Cards/AuthCard';
import TextField from 'components/Inputs/TextField';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import GoogleSSO from './GoogleSso';
// import Grid from '@material-ui/core/Grid';
import { landingVisitorsLogs } from '../../helper/visitorsApi';

const styles = (theme) => ({
  submit: {
    marginTop: theme.spacing(2),
  },
  footer: {
    display: 'flex',
    justifyContent: 'space-around',
  },
});

const LoginForm = ({ onSubmit, classes, authError, gotoForgot, loggingIn }) => {
  const { t } = useTranslation();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [ssoError, setSsoError] = useState('');
  const [valid, setValid] = useState({
    username: false,
    password: false,
  });
  useEffect(() => {
    landingVisitorsLogs();
  }, []);
  const isValid = () => {
    return valid.username && valid.password;
  };

  const validate = (name, value) => {
    if (name === 'username') {
      if (!value || value.length === 0) {
        setValid({ ...valid, username: false });
        return {
          invalid: true,
          message: t('required'),
        };
      }
      setValid({ ...valid, username: true });
    } else if (name === 'password') {
      if (!value || value.length === 0) {
        setValid({ ...valid, password: false });
        return {
          invalid: true,
          message: t('required'),
        };
      }
      setValid({ ...valid, password: true });
    }
    return undefined;
  };

  return (
    <AuthCard
      errorMessage={authError && t(_.snakeCase(authError))}
      footer={
        <div className={classes.footer}>
          <Button size="small" onClick={gotoForgot}>
            Forgot your Password? Here to help.
          </Button>
        </div>
      }
    >
      {loggingIn ? <LinearProgress /> : <div style={{ height: 4 }} />}
      {ssoError && <div style={{ color: 'red' }}>{ssoError}</div>}
      <form
        onSubmit={(event) => {
          event.preventDefault();
          onSubmit({ username, password });
        }}
      >
        <TextField
          required
          validate={validate}
          name="username"
          autoComplete="username"
          value={username}
          label={"Username"}
          disabled={loggingIn}
          setValue={(v) => setUsername(v)}
        />
        <TextField
          required
          autoComplete="current-password"
          validate={validate}
          name="password"
          value={password}
          type="password"
          label="Password"
          disabled={loggingIn}
          setValue={(v) => setPassword(v)}
        />
        <Button
          fullWidth
          variant="contained"
          size="large"
          color="primary"
          type="submit"
          disableElevation
          disabled={!isValid() || loggingIn}
          className={classes.submit}
        >
          Sign In
        </Button>
      </form>
      <GoogleSSO setSsoError={setSsoError} />
    </AuthCard>
  );
};

LoginForm.propTypes = {
  classes: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  authError: PropTypes.string,
  gotoForgot: PropTypes.func.isRequired,
  loggingIn: PropTypes.bool,
};

LoginForm.defaultProps = {
  authError: undefined,
  loggingIn: false,
};

export default withStyles(styles)(LoginForm);
