/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/img-redundant-alt */
import { Box, Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Checkbox from '@mui/material/Checkbox';
import { useWindowDimensions } from 'hooks';
import useImageEngine from '../../hooks/useImageEngine';
import { ImageViewer, UploadForm } from './components';
import { fetchImagesData, processImageApi, getProcessedImageData } from '../../action/index.js';
import { useDispatch, useSelector } from 'react-redux';
import Cookies from 'js-cookie'
import { useAlgorithms } from '../../hooks';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { toast } from 'react-toastify';
import AppLoader from './appLoader/AppLoader.js'
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import InsertPhotoOutlinedIcon from '@mui/icons-material/InsertPhotoOutlined';
import MemoryOutlinedIcon from '@mui/icons-material/MemoryOutlined';
import SlideshowOutlinedIcon from '@mui/icons-material/SlideshowOutlined';
import OpenSeadragon from 'openseadragon';
import imgview from "../dashboard/imgview.png";
import ReactSelect, { components } from "react-select";

const Dashboard = () => {
    const colSpacings = { xs: 1, sm: 2, md: 3 };
    const { getAlgoList } = useAlgorithms();
    const { setActiveImage } = useImageEngine();
    const { height } = useWindowDimensions();
    const dispatch = useDispatch();
    const [imageDropdownName, setImageDropdownName] = React.useState([]);
    const [processImgList, setProcessImgList] = useState('');
    const [processImgName, setProcessImgName] = useState([]);
    const [stainListData, setStainListData] = useState([]);
    const [checkedRedio, setCheckedRedio] = useState("vIHC");
    const [algoName, setAlgoName] = useState('');
    const [processedImageName, setProcessedImageName] = useState([]);
    const [processalgoData, setprocessalgoData] = useState('');
    const [isEnabled, setIsEnabled] = useState(false);
    const [isLoader, setLoader] = useState(false);
    const [uploadedImgDes, setUploadedImgDes] = useState('');
    const [uploadedImgTag, setUploadedImgTag] = useState('');
    const [viewer, setViewer] = useState('');
    const [imageLoader, setImageLoader] = useState(false);
    const [selectedOption, setSelectedOption] = useState('');
    const [showDisabled, setShowDisabled] = useState(true);

    const dataEn = useSelector((state) => {
        return state.fetchImageData.data;
    })
    const AlgoDataState = useSelector((state) => state.AlgoData);
    const ProcessedData = useSelector((state) => state.processedImageData.data);
    const imageViewSections = ProcessedData;

    useEffect(() => {
        if (isEnabled) {
            if (AlgoDataState && AlgoDataState.status === 'success') {
                toast.success("Successfully processed samples image");
                dispatch(processImageApi({}, true));
                setIsEnabled(false);
                setLoader(false);
                dispatch(getProcessedImageData());
            } else {
                toast.error("Something went wrong");
                dispatch(processImageApi({}, true));
                setIsEnabled(false);
                setLoader(false);
            }
        }
    }, [AlgoDataState]);

    useEffect(() => {
        const user_id = Cookies.get('user_id');
        dispatch(fetchImagesData(user_id));
        dispatch(getProcessedImageData());
    }, []);

    /** 
    * @function handleUpload [API call for process image]
    * @param {string} item
    */
    const handleUpload = () => {
        const user_id = Cookies.get('user_id');
        const org_id = Cookies.get('org_id');
        const algo_id = processalgoData.id;
        const user_upload_id = processImgList.id;
        const sample_image_location = processImgList.file_location;
        const processed = false
        const sample_name = processImgList.file_name;
        const description = 'description';
        const file_format = "image/jpeg";
        const file_uri = processImgList.file_uri;
        const stain_list = stainListData.toString();
        const v_multiplex = (checkedRedio === 'vMultiplex' && showDisabled === false) ? true : false;
        const v_ihc = checkedRedio === 'vIHC' || (checkedRedio === 'vMultiplex' && showDisabled) ? true : false;
        const flow = checkedRedio === 'vFlow' ? true : false;

        const processImageData = {
            user_id: user_id,
            organization_id: org_id,
            algo_id: algo_id,
            user_upload_id: user_upload_id,
            sample_image_location: sample_image_location,
            processed: processed,
            sample_name: sample_name,
            description: description,
            file_format: file_format,
            file_uri: file_uri,
            stain_list: stain_list,
            v_multiplex: v_multiplex,
            v_ihc: v_ihc,
            flow: flow
        }
        dispatch(processImageApi(processImageData))
        setSelectedOption('');
        setIsEnabled(true);
        setProcessImgList('');
        setProcessImgName('');
        setAlgoName('');
        setLoader(true);
    }

    /** 
    * @function handleChange [select the dropdown Uploaded image list]
    * @param {string} event
    */
    const handleChange = (event: SelectChangeEvent) => {
        setImageLoader(true);
        const uploadedFieldData = event.target.value;
        setUploadedImgDes(uploadedFieldData.image_desc);
        setUploadedImgTag(uploadedFieldData.image_tag);
        setImageDropdownName([event.target.value.file_name]);
        const dk = event.target.value;
        setActiveImage(event.target.value)
        const imageUrl = `https://${window.env.REACT_APP_STORAGE_NAME}.blob.core.windows.net${dk.file_location}`;
        if (viewer) {
            window.GeoTIFFTileSource(OpenSeadragon);
            viewer.addHandler('tile-drawn', () => {
                setImageLoader(false);
            });
            let tiffTileSources = OpenSeadragon.GeoTIFFTileSource.getAllTileSources(imageUrl, { logLatency: true });
            tiffTileSources.then(ts => viewer.open(ts));
        }
    };

    /** 
    * @function handleChangeProcessImage [select the dropdown Process image list]
    * @param {string} event
    */
    const handleChangeProcessImage = (event: SelectChangeEvent) => {
        setProcessImgList(event.target.value);
        setProcessImgName([event.target.value.file_name]);
    };

    /** 
    * @function handleChangeProcessAlgo [select the dropdown Process Algorithm list]
    * @param {string} e
    */
    const handleChangeProcessAlgo = (e) => {
        setAlgoName(e.target.value.algo_name);
        setprocessalgoData(e.target.value);
    };

    /** 
    * @function handleCheckboxChange [Choose the Stain List]
    * @param {string} e
    */
    const handleCheckboxChange = (e) => {
        const { value, checked } = e.target;
        if (checked) {
            setStainListData([...stainListData, value])
        } else {
            setStainListData(stainListData.filter((e) => e !== value))
        };
    };

    /** 
    * @function handleRedioboxChange [choose the model]
    * @param {string} e
    */
    const handleRedioboxChange = (e) => {
        const { value, checked } = e.target;
        if (checked) {
            setCheckedRedio(value);
        };
    };

    const handleCallBack = (data) => {
        setLoader(data);
    };

    /** 
    * @function handleChangeProcessedImageData [Select the processed images List]
    * @param {object} item
    */
    const handleChangeProcessedImageData = (item) => {
        setProcessedImageName([item.target.value.sample_name]);
        setprocessalgoData(item.target.value);
        setActiveImage(item.target.value);
    };

    useEffect(() => {
        viewer && viewer.destroy();
        setViewer(
            OpenSeadragon({
                id: "diz_openSeaDragon",
                prefixUrl: "openseadragon-images/",
                minZoomImageRatio: 1, // stops zoom out more than the preview
                minPixelRatio: 1.5, // lower quality to save data for the preview
                immediateRender: true,
                maxZoomLevel: 1, // stops user from zooming, may be unintented functionality, can set to n for nx zoom
                gestureSettingsMouse: { dragToPan: false }, //if zoom is not needed
                defaultZoomLevel: 1,
                visibilityRatio: 1,
                crossOriginPolicy: 'Anonymous',
                ajaxWithCredentials: true,
                sequenceMode: true,
                showHomeControl: false,
                showZoomControl: false,
                showNavigationControl: false,
                showSequenceControl: false,
            })
        );
    }, []);

    const colourOptions = [
        { value: "er", label: "er" },
        { value: "pr", label: "pr" },
        { value: "her2", label: "her2" }
    ];

    /** 
    * @function handleChangeStainList [Select the Stain List from redio button]
    * @param {object} selectedOption
    */
    const handleChangeStainList = (selectedOption) => {
        if (selectedOption) {
            setSelectedOption(selectedOption);
            const newArray = []
            selectedOption.map((data) => {
                newArray.push(data.value);
            })
            if (newArray && newArray.length > 1) {
                setShowDisabled(false)
            } else if (newArray && newArray.length < 2) {
                setShowDisabled(true)
                if (checkedRedio === 'vMultiplex') {
                    setCheckedRedio('vIHC');
                }

            }
            setStainListData(newArray);
        }
    }

    const Option = (props) => {
        return (
            <div>
                <components.Option {...props}>
                    <input
                        type="checkbox"
                        checked={props.isSelected}
                        onChange={() => null}
                    />{" "}
                    <label>{props.label}</label>
                </components.Option>
            </div>
        );
    };

    return (
        <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={2} >
                <Grid item xs={4}>
                    {isLoader && <AppLoader />}
                    <Accordion >
                        <AccordionSummary className='accbg'
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel2a-content"
                            id="panel2a-header"
                        >
                            <Typography className='headcss'>
                                <CloudUploadOutlinedIcon className='icon' />
                                Upload Image
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <UploadForm handleCallBack={handleCallBack} />
                        </AccordionDetails>
                    </Accordion>
                    <Accordion>
                        <AccordionSummary className='accbg'
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-uploaded-header"
                        >

                            <Typography className='headcss'>
                                <InsertPhotoOutlinedIcon className='icon' />
                                Uploaded Images
                            </Typography>
                        </AccordionSummary>
                        <div>
                            <AccordionDetails className='dspflx'>

                                <Box component="fieldset" className='fieldset'

                                    noValidate
                                    autoComplete="off"
                                >
                                    <Box className='legend' component="legend">Uploaded Images</Box>
                                    <div className='wrapsec2'>
                                        <Typography variant='h6' className='headtxtcss fntb14'>
                                            Image :
                                        </Typography>
                                        <FormControl className='selectbox'>
                                            <Select
                                                labelId="demo-simple-select-helper-label"
                                                id="demo-simple-select-helper"
                                                value={imageDropdownName}
                                                onChange={(data) => handleChange(data)}
                                                className='wh100'
                                                displayEmpty
                                                renderValue={(showPlaceholder) => {
                                                    if (showPlaceholder.length === 0) {
                                                        return <em style={{ fontStyle: 'normal' }}>Select the Image</em>;
                                                    }
                                                    return showPlaceholder;
                                                }}
                                            >
                                                {dataEn && dataEn.length > 0 && dataEn.map((item) => (
                                                    <MenuItem value={item} key={item.id}>{item.file_name}</MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </div>
                                    <div className='wrapsec2'>
                                        <Typography variant='h6' className='headtxtcss fntb14'>Image Description:</Typography>
                                        <Typography variant='p'>{uploadedImgDes}</Typography>
                                        <Typography variant='h6' className='headtxtcss fntb14'>Image Tag:</Typography>
                                        <Typography variant='p'>{uploadedImgTag}</Typography>
                                    </div>
                                    <div className='wrapsec2'>
                                        <Typography variant='h6' className='headtxtcss fntb14'>Image:</Typography>
                                        {imageLoader && <p style={{ fontWeight: 'bold' }}>Please wait...Image is loading...</p>}
                                        <div id="diz_openSeaDragon" className='wh200'></div>
                                    </div>
                                    <div style={{ clear: 'both' }}></div>
                                </Box>
                            </AccordionDetails>
                        </div>
                    </Accordion>
                    <Accordion>
                        <AccordionSummary className='accbg'
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel2a-content"
                            id="panel2a-header"
                        >
                            <Typography className='headcss'>
                                <MemoryOutlinedIcon className='icon' />
                                Process Images
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Box component="fieldset" className='fieldset'
                                noValidate
                                autoComplete="off"
                            >
                                <Box className='legend' component="legend">Process the Image</Box>
                                <div className='wrapsec2'>
                                    <Typography className='w100 mt10 fntb14'>
                                        Modalities :
                                    </Typography>
                                    <RadioGroup
                                        row
                                        aria-labelledby="demo-row-radio-buttons-group-label"
                                        name="row-radio-buttons-group"
                                    >
                                        <FormControlLabel className='pd0' checked={checkedRedio === "vIHC"} value="vIHC" control={<Radio onChange={(e) => handleRedioboxChange(e)} />} label="vIHC" />
                                        <FormControlLabel className='pd0' checked={checkedRedio === "vMultiplex"} disabled={showDisabled} value="vMultiplex" control={<Radio onChange={(e) => handleRedioboxChange(e)} />} label="vMULTIPLEX" />
                                        <FormControlLabel className='pd0' checked={checkedRedio === "vFlow"} value="vFlow" control={<Radio onChange={(e) => handleRedioboxChange(e)} />} label="FLOW" />
                                    </RadioGroup>
                                </div>
                                <div className='wrapsec2'>
                                    <Typography className='w100 mt10 fntb14'>
                                        Stain List :
                                    </Typography>

                                    <ReactSelect className='drp'
                                        type="checkbox"
                                        isMulti
                                        onChange={handleChangeStainList}
                                        options={colourOptions}
                                        closeMenuOnSelect={false}
                                        hideSelectedOptions={false}
                                        allowSelectAll={true}
                                        components={{ Option }}
                                        placeholder={'Select the Stain List'}
                                        styles={{
                                            placeholder: (base) => ({
                                                ...base,
                                                fontSize: '1.347em',
                                                color: colourOptions[2].color,
                                                fontWeight: 400,
                                            }),

                                        }}
                                        value={selectedOption}
                                    />
                                </div>
                                <div className='wrapsec2'>
                                    <Typography className='w100 mt10 fntb14'>
                                        Image :
                                    </Typography>
                                    <FormControl className='selectbox'>
                                        <Select
                                            labelId="demo-simple-select-helper-label"
                                            id="demo-simple-select-helper"
                                            value={processImgName}
                                            onChange={(data) => handleChangeProcessImage(data)}
                                            className='wh100'
                                            displayEmpty
                                            renderValue={(showPlaceholder) => {
                                                if (showPlaceholder.length === 0) {
                                                    return <em style={{ fontStyle: 'normal' }}>Select the Image</em>;
                                                }
                                                return showPlaceholder;
                                            }}
                                        >
                                            {dataEn && dataEn.length > 0 && dataEn.map((item) => (
                                                <MenuItem value={item} key={item.id}>{item.file_name}</MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </div>
                                <div className='wrapsec2'>
                                    <Typography className='w100 mt10 fntb14'>
                                        Algorithm :
                                    </Typography>
                                    <FormControl className='selectbox'>
                                        <Select
                                            labelId="demo-simple-select-helper-label"
                                            id="demo-simple-select-helper"
                                            value={algoName}
                                            onChange={(e) => handleChangeProcessAlgo(e)}
                                            className='wh100'
                                            displayEmpty
                                            renderValue={(algoName) => {
                                                if (algoName.length === 0) {
                                                    return <em style={{ fontStyle: 'normal' }}>Select the Algorithm</em>;
                                                }
                                                return algoName;
                                            }}
                                        >
                                            {getAlgoList && getAlgoList.length && getAlgoList.map((item) => (
                                                <MenuItem value={item} key={item.id}>{item.algo_name}</MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                    <Button className='btnprog mt10' variant="contained" onClick={() => {
                                        handleUpload()
                                    }}>Process Image</Button>
                                </div>
                            </Box>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion>
                        <AccordionSummary className='accbg'
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <Typography className='headcss'>
                                <SlideshowOutlinedIcon className='icon' />
                                Slide Gallery
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Box component="fieldset" className='fieldset slide'
                                noValidate
                                autoComplete="off"
                            >
                                <Box className='legend' component="legend">Slide Gallery</Box>
                                <div className='wrapsec2'>
                                    <Typography className='w100 mt10 fntb14'>
                                        Image :
                                    </Typography>
                                    <div className='wdg100'>
                                        <FormControl className='mlr'>
                                            <Select
                                                labelId="demo-simple-select-helper-label"
                                                id="demo-simple-select-helper"
                                                value={processedImageName}
                                                onChange={(data) => handleChangeProcessedImageData(data)}
                                                displayEmpty
                                                className='wh100'
                                                renderValue={(processedImageName) => {
                                                    if (processedImageName.length === 0) {
                                                        return <em style={{ fontStyle: 'normal' }}>Select the Image</em>;
                                                    }
                                                    return processedImageName;
                                                }}
                                            >
                                                {imageViewSections && imageViewSections.length > 0 && imageViewSections.map((item) => (
                                                    <MenuItem value={item} key={item.cancer_sample_upload_id}>{item.sample_name}</MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </div>
                                </div>
                            </Box>
                        </AccordionDetails>
                    </Accordion>
                </Grid>
                <Grid item justifyContent="center" xs={8} sx={{ minHeight: '100%' }}>
                    <Box className="imgviewwrap">
                        {processedImageName.length === 0 && <h1 className='imgview'><span>Image Viewer</span>
                            <img src={imgview} />
                        </h1>}
                        <ImageViewer/> {/* show the image viewer */}
                    </Box>
                </Grid>
            </Grid>
        </Box>
    )
}

export default Dashboard;